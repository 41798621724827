#interactive-refresh-ad {
	display: none;
}

$black: #000000;
// $bg: #72b27e;
$bg: #518e5a;


#john-kandrac {


	padding: 2em 0 10em 0;
	font-family: 'Heebo', sans-serif;
	font-weight: 300;

	a {
		color: $bg;
		border-bottom: 2px solid $bg;
		text-decoration: none;
	}

	h1, h3 {
		font-family: 'Libre Baskerville', serif;
		font-weight: normal;
	}

	h3 {
		margin: 0;
		padding: 0;
		font-size: 2em;
		font-weight: normal;
	}

	h4 {
		margin: 0;
		padding: 0;
		font-size: 1em;
		font-weight: 500;
		letter-spacing: 1.5px;
		text-transform: uppercase;
	}

	.jpk-clear {
		float: none;
		clear: both;
		display: block;
	}

	.jpk-wrapper {
		max-width: 800px;
		margin: 0 auto;
		padding: 0 1em;
	}

	.jpk-col1,
	.jpk-col2 {
		display: inline-block;
		vertical-align: top;
	}

	.jpk-col1 {
		max-width: 560px;
		margin: 0 56px 0 0;
		// width: 70%;
		// margin: 0 7% 0 0;

		@media (max-width: 500px) {
			margin: 0 20px 0 0;
		}
	}

	.jpk-col2 {
		// max-width: 176px;
		// width: 22%;
	}


	/* intro styles */

	.jpk-introWrapper {
		// height: 75vh;
		margin: 0 0 6em 0;

		@media (max-width: 600px) {
			height: auto;
		}
	}

	.jpk-intro {
		padding: 6em 0 0 0;

		h1, p {
			margin: 0;
			padding: 0;
		}

		h1 {
			margin: 0 0 0.5em 0;
			font-size: 2em;
			font-weight: normal;
		}

		p {
			font-size: 1.2em;
			line-height: 1.8em;
		}

		span {
			font-weight: 500;
		}

		.jpk-headshot-mobile,
		.jpk-headshot-desktop {
			img {
				width: inherit;
			}
		}

		.jpk-headshot-mobile {
			display: none;
			width: 120px;
			margin: 0 0 2em 0;
		}

		.jpk-headshot-desktop {
			display: inline-block;
		}

		.jpk-col1 {
			width: 430px;
			margin: 0;
			// width: 55%;
		}

		.jpk-col2 {
			width: 200px;
			margin: 0 0 0 80px;
			// width: 25%;
			// margin: 0 0 0 9%;
		}

		@media (max-width: 800px) {

			.jpk-col1 {
				width: 380px;
			}

			.jpk-col2 {
				width: 180px;
			}
		}

		@media (max-width: 680px) {
			padding: 3em 0 6em 0;

			.jpk-headshot-mobile {
				display: block;
			}

			.jpk-headshot-desktop {
				display: none;
			}

			.jpk-col1 {
				width: 100%;
			}

			.jpk-col2 {
				width: 180px;
			}
		}
	}

	.jpk-introCopy {
		p {
			margin: 0 0 1.5em 0;
		}
	}

	/* contact list */

	.jpk-contactList {
		margin: 1em 0 0 0;

		p {
			display: inline-block;
			margin: 0 0.7em 0 0;
			font-size: 1em;
		}
	}

	.jpk-scrollDown {
		margin: 8em 0 0 0;

		img {
			width: 6em;
		}
	}


	/* sections */

	.jpk-section {
		p {
			// font-size: 1em;
			// line-height: 1.7em;
			font-size: 1.2em;
			line-height: 1.7em;
		}
	}

	.jpk-subsection {
		margin: 3em 0 0 0;
	}

	.jpk-pub {
		margin: 0 0 1em 0;
	}

	.jpk-pub-presentations {
		margin: 0 0 0 2em;
		font-size: 0.85em;
	}

	.jpk-ssrn-link {
		// padding: 0 0 0 2em;
		font-size: 0.8em;
		font-weight: 500;
	}


}